import React from "react";
import { Link, graphql } from "gatsby";

import "../assets/fonts/inter.css";
import styled, { createGlobalStyle } from "styled-components";
import { css } from "emotion";
import tw from "tailwind.macro";
import Layout from "../components/Layout";
import NavBar from "../components/NavBar";
import Row from "../components/Row";
import Card from "../components/Card";
import { Waypoint } from "react-waypoint";
import Menu from "../components/Menu";
import { Spring, animated, interpolate } from "react-spring/renderprops";
import Moment from "moment";
import Img from "gatsby-image";
import Prismic from "prismic-javascript";
import GoogleAd from "../components/GoogleAd";
import Helmet from "react-helmet";
import AdCard from "../components/AdCard";
import HeroAdCard from "../components/HeroAdCard";
// import AdSense from 'react-adsense';
// import GoogleAd from "../components/AdCard";
import Adsense from "react-adsense";
import ReactGA from "react-ga";
import HorizontalCard from "../components/HorizontalCard";
ReactGA.initialize("UA-141669883-1");

export const GlobalStyle = createGlobalStyle`
  html{
    font-family: 'Inter', 'Helvetica Nueue', sans-serif;
    font-size: 16px;
  }
  body{
    ${tw`m-0`};
    box-sizing: border-box !important;
    
  }
  p{
    margin: 0;
  }
  div{
    box-sizing: border-box !important;
  }
  `;

const SectionOne = styled.section`
    ${tw`flex flex-wrap w-screen h-auto relative z-0`};
    background: #f3f7fc;
    span {
        ${tw`absolute z-back w-full bg-black pin-t pin-l`};
        height: 100%;
    }
`;
const SectionTwo = styled.section`
    ${tw`min-h-screen py-16`};
    background-image: linear-gradient(-180deg, #f3f7fc 28%, #e4ebf7 100%);
`;

const CategoriesMenu = styled.div`
    ${tw`w-full max-w-row mx-auto text-lg overflow-hidden`};
    ${(props) => props.fixed && tw`fixed pin-t pin-x z-500`};
    height: 3rem;
    margin-top: 4rem;
    border-radius: 0 0 8px 8px;
`;

const ScrollableArea = styled.div`
    ${tw`flex flex-no-wrap items-center w-screen overflow-x-scroll pb-24 pr-16`};
    -webkit-overflow-scrolling: touch;
`;

const CategoryTab = styled.div`
    ${tw`lg:flex-1 min-w-24 mx-6 whitespace-no-wrap inline-flex justify-between flex-no-wrap py-2 text-center font-semibold tracking-wide uppercase text-sm opacity-50 rounded-lg cursor-pointer hover:opacity-70`};
    ${(props) => props.active && tw`font-bold opacity-100`};
    ${(props) => props.active && `background-color: rgba(200,200,220,0.1);`};
`;
const FixedCategoriesMenu = styled.div`
    ${tw`fixed pin-t pin-x w-full max-w-row mx-auto py-2 bg-black z-500 overflow-hidden`};
    border-radius: 0 0 8px 8px;
    height: 3rem;
    ${CategoryTab} {
        ${tw`text-white`}
    }
`;
const AnimatedFixedCategoriesMenu = animated(FixedCategoriesMenu);

const apiEndpoint = "https://prad.prismic.io/api/v2";

function sleepTime(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedInView: false,
            showMenu: false,
            invertNav: false,
            feedCategory: "all",
            fixCategoryNav: false,
            showSearch: false,
            searchResults: [],
            city: "",
            temp: "",
            noResultsFound: false,
        };
        this.cards = null;
        this.topOneCards = [];
        this.topTwoCards = [];
        this.topThreeCards = [];
        this.topFourCards = [];
        this.topFiveCards = [];
        this.sectionOneEnd = this.sectionOneEnd.bind(this);
        this.toggleShowMenu = this.toggleShowMenu.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.handleFeedClick = this.handleFeedClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.isEmptyArray = this.isEmptyArray.bind(this);
        this.checkAndPickTop5Cards = this.checkAndPickTop5Cards.bind(this);
    }

    sectionOneEnd() {
        // alert('Section One End');
        this.setState({ feedInView: true, invertNav: true });
    }

    toggleShowMenu(type) {
        let that = this;
        this.setState(
            {
                showMenu: !this.state.showMenu,
                invertNav: !this.state.invertNav,
            },
            function() {
                //console.log('state Change: ' + JSON.stringify(this.state));
                if (type == "search") {
                    //console.log('Search Clicked');
                    this.setState({ showSearch: true });
                } else {
                    // console.log('Menu Clicked') ;
                    this.setState({ showSearch: false });
                    return;
                }
            }
        );
    }

    onCloseClick() {
        this.setState({
            showMenu: !this.state.showMenu,
            invertNav: !this.state.invertNav,
        });
    }

    handleKeyPress(e, text) {
        /*
    let self = this;
    if (e.key === 'Enter') {
      Prismic.api(apiEndpoint).then(api => {
        //debugger
        api.query([
          Prismic.Predicates.at('document.type', 'article'),
          Prismic.Predicates.fulltext('document', text)
        ]).then(function (response) {
         // debugger
          if (response.results.length == 0) {
            self.setState({ noResultsFound: true });
          }
          self.setState({ searchResults: response.results, noResultsFound: false });

          // response is the response object, response.results holds the documents
        });
      });
    }
    */
    }

    handleFeedClick(type) {
        //debugger
        this.cards = [];
        this.setState({ feedCategory: type });
        if (type == "all") {
            for (let i = 0; i < this.props.data.all.edges.length; i++) {
                this.cards.push(this.props.data.all.edges[i]);
            }
            for (let i = 0; i < this.props.data.gallery.edges.length; i++) {
                this.cards.push(this.props.data.gallery.edges[i]);
            }
        } else if (type == "politics") {
            if (this.props.data.politics) {
                this.cards = this.props.data.politics.edges;
            } else {
                this.cards = [];
            }
        } else if (type == "tech") {
            if (this.props.data.tech) {
                this.cards = this.props.data.tech.edges;
            } else {
                this.cards = [];
            }
        } else if (type == "entertainment") {
            let cards = [];
            for (
                let i = 0;
                i < this.props.data.entertainment.edges.length;
                i++
            ) {
                cards.push(this.props.data.entertainment.edges[i]);
            }
            this.cards = cards;
        } else if (type == "fashion") {
            let cards = [];
            if (this.props.data.fashion != undefined) {
                for (let i = 0; i < this.props.data.fashion.edges.length; i++) {
                    cards.push(this.props.data.fashion.edges[i]);
                }
            }
            this.cards = cards;
        } else if (type == "health") {
            let cards = [];
            if (this.props.data.health != undefined) {
                for (let i = 0; i < this.props.data.health.edges.length; i++) {
                    cards.push(this.props.data.health.edges[i]);
                }
            }
            this.cards = cards;
        } else if (type == "business") {
            let cards = [];
            if (this.props.data.business != undefined) {
                for (
                    let i = 0;
                    i < this.props.data.business.edges.length;
                    i++
                ) {
                    cards.push(this.props.data.business.edges[i]);
                }
            }
            this.cards = cards;
        } else if (type == "nri") {
            let cards = [];
            if (this.props.data.nri != undefined) {
                for (let i = 0; i < this.props.data.nri.edges.length; i++) {
                    cards.push(this.props.data.nri.edges[i]);
                }
            }
            this.cards = cards;
        } else if (type == "gallery") {
            for (let i = 0; i < this.props.data.gallery.edges.length; i++) {
                this.cards.push(this.props.data.gallery.edges[i]);
            }
        } else if (type == "review") {
            for (let i = 0; i < this.props.data.review.edges.length; i++) {
                this.cards.push(this.props.data.review.edges[i]);
            }
        }
    }

    /*
     * This function is used to check if the passed array is empty
     * pArrayObj - array object
     */
    isEmptyArray(pArrayObj) {
        if (
            typeof pArrayObj === "undefined" ||
            pArrayObj === null ||
            pArrayObj.length === null ||
            pArrayObj.length === 0
        ) {
            return true;
        }
        return false;
    }

    /*
     * This function is used to pick top 5 cards
     * which populates in main page header
     * pDocObj - document content object
     */
    checkAndPickTop5Cards(pDocObj) {
        if (pDocObj.node.tags.includes("one")) {
            if (this.isEmptyArray(this.topOneCards)) {
                this.topOneCards.push(pDocObj);
            }
        } else if (pDocObj.node.tags.includes("two")) {
            if (this.isEmptyArray(this.topTwoCards)) {
                this.topTwoCards.push(pDocObj);
            }
        } else if (pDocObj.node.tags.includes("three")) {
            if (this.isEmptyArray(this.topThreeCards)) {
                this.topThreeCards.push(pDocObj);
            }
        } else if (pDocObj.node.tags.includes("four")) {
            if (this.isEmptyArray(this.topFourCards)) {
                this.topFourCards.push(pDocObj);
            }
        } else if (pDocObj.node.tags.includes("five")) {
            if (this.isEmptyArray(this.topFiveCards)) {
                this.topFiveCards.push(pDocObj);
            }
        } else if (pDocObj.node.tags.includes("six")) {
            if (this.isEmptyArray(this.topFiveCards)) {
                this.topFiveCards.push(pDocObj);
            }
        }
    }

    componentWillMount() {
        this.cards = [];
        if (this.props.data) {
            if (this.props.data.review) {
                for (let i = 0; i < this.props.data.review.edges.length; i++) {
                    if (i < 5) {
                        this.cards.push(this.props.data.review.edges[i]);
                        this.cards.review
                            ? this.cards.review.push(
                                  this.props.data.review.edges[i]
                              )
                            : (this.cards.review = [
                                  this.props.data.review.edges[i],
                              ]);
                        this.checkAndPickTop5Cards(
                            this.props.data.review.edges[i]
                        );
                    }
                }
            }
            if (this.props.data.all) {
                for (let i = 0; i < this.props.data.all.edges.length; i++) {
                    if (i < 50) {
                        this.cards.push(this.props.data.all.edges[i]);
                        this.cards.all
                            ? this.cards.all.push(this.props.data.all.edges[i])
                            : (this.cards.all = [this.props.data.all.edges[i]]);
                        this.checkAndPickTop5Cards(
                            this.props.data.all.edges[i]
                        );
                    }
                }
            }
            if (this.props.data.gallery) {
                for (let i = 0; i < this.props.data.gallery.edges.length; i++) {
                    if (i < 10) {
                        this.cards.push(this.props.data.gallery.edges[i]);
                        this.cards.gallery
                            ? this.cards.gallery.push(
                                  this.props.data.gallery.edges[i]
                              )
                            : (this.cards.gallery = [
                                  this.props.data.gallery.edges[i],
                              ]);
                        this.checkAndPickTop5Cards(
                            this.props.data.gallery.edges[i]
                        );
                    }
                }
            }
        }
    }

    render() {
        let one = this.topOneCards.length > 0 ? this.topOneCards[0] : [];
        let two = this.topTwoCards.length > 0 ? this.topTwoCards[0] : [];
        let three = this.topThreeCards.length > 0 ? this.topThreeCards[0] : [];
        let four = this.topFourCards.length > 0 ? this.topFourCards[0] : [];
        let five = this.topFiveCards.length > 0 ? this.topFiveCards[0] : [];
        ReactGA.pageview("AndhraHeadlines HomePage");
        return (
            <>
                <GlobalStyle />
                {console.log(this.props.data, "dataaaaaa")}
                {/* <Helmet
                script={[
                  {"src": "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", "type": "text/javascript"},
                ]}
              /> */}
                <Layout>
                    {/* <Google 

                  /> */}
                    {/* <Spring native
            from={{ y: -50, o: 0 }}
            to={{ y: this.state.feedInView ? 4 : -50, o: this.state.feedInView ? 1 : 0 }}
            delay={this.state.feedInView ? 200 : 0}>
            {values =>
              <AnimatedFixedCategoriesMenu style={{
                transform: values.y.interpolate(y => `translate3d(0, ${y}rem, 0)`),
                opacity: values.o.interpolate(o => o),
              }}>
                <ScrollableArea>

                  <CategoryTab
                    active={this.state.feedCategory === 'all'}
                    onClick={() => this.handleFeedClick('all')}
                  >
                    All News
                  </CategoryTab>
                  
                              <CategoryTab 
                                active={this.state.feedCategory === 'politics'}
                                onClick={() => this.handleFeedClick('politics')}
                              >
                                Politics
                              </CategoryTab>
                             
                  <CategoryTab
                    active={this.state.feedCategory === 'gallery'}
                    onClick={() => this.handleFeedClick('gallery')}
                  >
                    Gallery
                  </CategoryTab>
                  <CategoryTab
                    active={this.state.feedCategory === 'entertainment'}
                    onClick={() => this.handleFeedClick('entertainment')}
                  >
                    Entertainment
                  </CategoryTab>
                  <CategoryTab
                    active={this.state.feedCategory === 'review'}
                    onClick={() => this.handleFeedClick('review')}
                  >
                    Reviews
                  </CategoryTab>
                  
                              <CategoryTab 
                                active={this.state.feedCategory === 'fashion'}
                                onClick={() => this.handleFeedClick('fashion')}
                              >
                                Fashion
                              </CategoryTab>
                              <CategoryTab 
                                active={this.state.feedCategory === 'health'}
                                onClick={() => this.handleFeedClick('health')}
                              >
                                Health
                              </CategoryTab>
                              <CategoryTab
                                active={this.state.feedCategory === 'business'}
                                onClick={() => this.handleFeedClick('business')}
                              >
                                Business
                              </CategoryTab>
                              <CategoryTab 
                                active={this.state.feedCategory === 'nri'}
                                onClick={() => this.handleFeedClick('nri')}
                              >
                                NRI
                              </CategoryTab>
                              <CategoryTab 
                                active={this.state.feedCategory === 'tech'}
                                onClick={() => this.handleFeedClick('tech')}
                              >
                                Tech
                              </CategoryTab>
                             
                </ScrollableArea>

              </AnimatedFixedCategoriesMenu>
            }
          </Spring> */}

                    <NavBar
                        dark={this.state.feedInView || this.state.invertNav}
                        menuClick={() => this.toggleShowMenu("menu")}
                        onSearchClick={() => this.toggleShowMenu("search")}
                        onCloseClick={this.onCloseClick}
                        showClose={this.state.showMenu}
                    />
                    <Menu
                        active={this.state.showMenu}
                        showSearch={this.state.showSearch}
                        handleKeyPress={this.handleKeyPress}
                        searchResults={this.state.searchResults}
                        noResultsFound={this.state.noResultsFound}
                    />
                    <SectionOne>
                        <Row
                            justifyBetween
                            className={css`
                                ${tw`mt-24`};
                                margin-top: 10rem;
                                height: 100;
                            `}
                        >
                            {/* <Adsense.Google
                client='ca-pub-5630104718741028'
                slot='9699731559'
                style={{ marginLeft: "auto", marginRight: "auto", display: "block", height: 90, width: 728 }}
                format='auto'
                responsive='true'
              /> */}
                            <h1
                                className={css`
                                    ${tw`w-full text-white font-thin opacity-40 m-0 pl-8 mt-4`}
                                `}
                            >
                                Featured News
                            </h1>
                            <div
                                className={css`
                                    ${tw`flex flex-wrap lg:mt-2`}
                                `}
                                style={{ width: "100%" }}
                            >
                                <div
                                    className={css`
                                        ${tw`flex flex-row flex-wrap`}
                                    `}
                                    style={{
                                        width: "100%",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        padding: 10,
                                    }}
                                >
                                    {/* <ins
                        style={{ display: "block" }}
                        className={`adsbygoogle`}
                        data-ad-client={'ca-pub-5630104718741028'}
                        data-ad-slot={"auto"}
                        data-ad-format={"6988031989"}
                        data-adtest='on'
                        // style="display:block"
                        enable_page_level_ads={true}
                        data-full-width-responsive="true"
                      >
                      </ins> */}
                                    {/* <Adsense.Google
                          client='ca-pub-5630104718741028'
                          slot='6988031989'
                          style={{ display: "block", height: 300, width: 200 }}
                      /> */}
                                    {/* <div style={{display: 'flex', justifyContent: 'space-around'}}> */}
                                    {one.hasOwnProperty("node") && (
                                        <Card
                                            title={one.node.data.title.text}
                                            img={one.node.data.img.url}
                                            date={Moment(
                                                one.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                one.node.data.datetime
                                            ).format("h A")}
                                            uid={one.node.uid}
                                            category={
                                                one.node.data.categories
                                                    ? one.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            hero
                                        />
                                    )}
                                    {two.hasOwnProperty("node") && (
                                        <Card
                                            title={two.node.data.title.text}
                                            img={two.node.data.img.url}
                                            date={Moment(
                                                two.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                two.node.data.datetime
                                            ).format("h A")}
                                            uid={two.node.uid}
                                            category={
                                                two.node.data.categories
                                                    ? two.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            hero
                                        />
                                    )}
                                    {/* </div> */}
                                    {/* <div style={{display: 'flex', justifyContent: 'space-around'}}> */}
                                    {three.hasOwnProperty("node") && (
                                        <Card
                                            title={three.node.data.title.text}
                                            img={three.node.data.img.url}
                                            date={Moment(
                                                three.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                three.node.data.datetime
                                            ).format("h A")}
                                            uid={three.node.uid}
                                            category={
                                                three.node.data.categories
                                                    ? three.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            hero
                                        />
                                    )}
                                    {four.hasOwnProperty("node") && (
                                        <Card
                                            title={four.node.data.title.text}
                                            img={four.node.data.img.url}
                                            date={Moment(
                                                four.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                four.node.data.datetime
                                            ).format("h A")}
                                            uid={four.node.uid}
                                            category={
                                                four.node.data.categories
                                                    ? four.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            hero
                                        />
                                    )}
                                    {/* </div> */}
                                    {/* <ins
                          style={{ display: "block" }}
                          className={`adsbygoogle`}
                          data-ad-client={'ca-pub-5630104718741028'}
                          data-ad-slot={"auto"}
                          data-ad-format={"6988031989"}
                          data-adtest='on'
                          enable_page_level_ads={true}
                          data-full-width-responsive="true"
                        >
                        </ins> */}
                                </div>
                                <Waypoint
                                    onEnter={() =>
                                        this.setState({ feedInView: false })
                                    }
                                    onLeave={() =>
                                        this.setState({ feedInView: true })
                                    }
                                    scrollableAncestor={"window"}
                                    offset={-200}
                                />
                                {/* <div
                                    className={css`
                                        ${tw`flex flex-wrap  items-start lg:px-2 w-full lg:w-1/3`}
                                    `}
                                >
                                    {three.hasOwnProperty("node") && (
                                        <Card
                                            title={three.node.data.title.text}
                                            img={three.node.data.img.url}
                                            date={Moment(
                                                three.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                three.node.data.datetime
                                            ).format("h A")}
                                            uid={three.node.uid}
                                            category={
                                                three.node.data.categories
                                                    ? three.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            side
                                        />
                                    )}
                                    {four.hasOwnProperty("node") && (
                                        <Card
                                            title={four.node.data.title.text}
                                            img={four.node.data.img.url}
                                            date={Moment(
                                                four.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                four.node.data.datetime
                                            ).format("h A")}
                                            uid={four.node.uid}
                                            category={
                                                four.node.data.categories
                                                    ? four.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            side
                                        />
                                    )}
                                    {five.hasOwnProperty("node") && (
                                        <Card
                                            title={five.node.data.title.text}
                                            img={five.node.data.img.url}
                                            date={Moment(
                                                five.node.data.datetime
                                            ).format("DD MMMM, YYYY")}
                                            time={Moment(
                                                five.node.data.datetime
                                            ).format("h A")}
                                            uid={five.node.uid}
                                            category={
                                                five.node.data.categories
                                                    ? five.node.data
                                                          .categories[0]
                                                          .category.document[0]
                                                          .data.name
                                                    : ""
                                            }
                                            side
                                        />
                                    )}
                                </div> */}
                            </div>
                        </Row>
                    </SectionOne>

                    <SectionTwo>
                        {/* <CategoriesMenu>
                        <ScrollableArea>

                            <CategoryTab 
                              active={this.state.feedCategory === 'all'} 
                              onClick={() => this.handleFeedClick('all')}
                            >
                              All News
                            </CategoryTab>
                            <CategoryTab 
                              active={this.state.feedCategory === 'politics'}
                              onClick={() => this.handleFeedClick('politics')}
                            >
                              Politics
                            </CategoryTab>
                            <CategoryTab 
                              active={this.state.feedCategory === 'entertainmentandfashion'}
                              onClick={() => this.handleFeedClick('entertainmentandfashion')}
                            >
                              Entertainment &amp; Fashion
                            </CategoryTab>
                            <CategoryTab 
                              active={this.state.feedCategory === 'tech'}
                              onClick={() => this.handleFeedClick('tech')}
                            >
                              Tech
                            </CategoryTab>
                        </ScrollableArea>
                      </CategoriesMenu> */}
                        <Row justifyAround>
                            {/* <Adsense.Google
                client='ca-pub-5630104718741028'
                slot='9699731559'
                style={{ marginLeft: "auto", marginRight: "auto", display: "block", height: 90, width: 728 }}
                format='auto'
                responsive='true'
              /> */}
                            <h1
                                className={css`
                                    ${tw`w-full text-black font-thin opacity-40 m-0 pl-8 mt-4`}
                                `}
                            >
                                Movie Reviews
                            </h1>
                            {this.cards.review.map((card, i) => {
                                // Skip duplicates
                                if (
                                    // card.node.uid !==
                                    //     (one.hasOwnProperty("node") &&
                                    //         one.node.uid) &&
                                    // card.node.uid !==
                                    //     (two.hasOwnProperty("node") &&
                                    //         two.node.uid) &&
                                    // card.node.uid !==
                                    //     (three.hasOwnProperty("node") &&
                                    //         three.node.uid) &&
                                    // card.node.uid !==
                                    //     (four.hasOwnProperty("node") &&
                                    //         four.node.uid) &&
                                    // card.node.uid !==
                                    //     (five.hasOwnProperty("node") &&
                                    //         five.node.uid)
                                    true
                                ) {
                                    // Check when to insert an ad card
                                    console.log(i, (i + 1) % 7);
                                    if ((i + 1) % 10 === 0) {
                                        return (
                                            <React.Fragment key={i}>
                                                <HorizontalCard
                                                    title={
                                                        card.node.data.title
                                                            .text
                                                    }
                                                    thirdPartyUrl={
                                                        card.node.data
                                                            .third_party_url
                                                    }
                                                    img={card.node.data.img.url}
                                                    date={Moment(
                                                        card.node.data.datetime
                                                    ).format("DD MMMM, YYYY")}
                                                    time={Moment(
                                                        card.node.data.datetime
                                                    ).format("h A")}
                                                    uid={card.node.uid}
                                                    category={
                                                        card.node.data
                                                            .categories
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                    }
                                                    content={
                                                        card.node.data.content
                                                            .text
                                                    }
                                                    feed
                                                />

                                                {/* Ad Card takes the space of 2 news cards */}
                                                <div style={{ width: "100%" }} className="ad-container">
                                                    <Adsense.Google
                                                        client="ca-pub-5630104718741028"
                                                        slot="9699731559"
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            display: "block",
                                                            height: 90,
                                                            width: 728,
                                                        }}
                                                        format="auto"
                                                        responsive="true"
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <HorizontalCard
                                                title={
                                                    card.node.data.title.text
                                                }
                                                thirdPartyUrl={
                                                    card.node.data
                                                        .third_party_url
                                                }
                                                img={card.node.data.img.url}
                                                date={Moment(
                                                    card.node.data.datetime
                                                ).format("DD MMMM, YYYY")}
                                                time={Moment(
                                                    card.node.data.datetime
                                                ).format("h A")}
                                                uid={card.node.uid}
                                                category={
                                                    card.node.data.categories
                                                        ? card.node.data
                                                              .categories
                                                              .length > 0
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                        : ""
                                                }
                                                content={
                                                    card.node.data.content.text
                                                }
                                                feed
                                                key={i}
                                            />
                                        );
                                    }
                                }
                            })}
                            <h1
                                className={css`
                                    ${tw`w-full text-black font-thin opacity-40 m-0 pl-8 mt-4`}
                                `}
                            >
                                News & Entertainment
                            </h1>
                            {this.cards.all.map((card, i) => {
                                // Skip duplicates
                                if (
                                    // card.node.uid !==
                                    //     (one.hasOwnProperty("node") &&
                                    //         one.node.uid) &&
                                    // card.node.uid !==
                                    //     (two.hasOwnProperty("node") &&
                                    //         two.node.uid) &&
                                    // card.node.uid !==
                                    //     (three.hasOwnProperty("node") &&
                                    //         three.node.uid) &&
                                    // card.node.uid !==
                                    //     (four.hasOwnProperty("node") &&
                                    //         four.node.uid) &&
                                    // card.node.uid !==
                                    //     (five.hasOwnProperty("node") &&
                                    //         five.node.uid)
                                    true
                                ) {
                                    // Check when to insert an ad card
                                    console.log(i, (i + 1) % 7);
                                    if ((i + 1) % 10 === 0) {
                                        return (
                                            <React.Fragment key={i}>
                                                <HorizontalCard
                                                    title={
                                                        card.node.data.title
                                                            .text
                                                    }
                                                    thirdPartyUrl={
                                                        card.node.data
                                                            .third_party_url
                                                    }
                                                    img={card.node.data.img.url}
                                                    date={Moment(
                                                        card.node.data.datetime
                                                    ).format("DD MMMM, YYYY")}
                                                    time={Moment(
                                                        card.node.data.datetime
                                                    ).format("h A")}
                                                    uid={card.node.uid}
                                                    category={
                                                        card.node.data
                                                            .categories
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                    }
                                                    content={
                                                        card.node.data.content
                                                            .text
                                                    }
                                                    feed
                                                />
                                                <div style={{ width: "100%" }} className="ad-container">
                                                    <Adsense.Google
                                                        client="ca-pub-5630104718741028"
                                                        slot="9699731559"
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            display: "block",
                                                            height: 90,
                                                            width: 728,
                                                        }}
                                                        format="auto"
                                                        responsive="true"
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <HorizontalCard
                                                title={
                                                    card.node.data.title.text
                                                }
                                                thirdPartyUrl={
                                                    card.node.data
                                                        .third_party_url
                                                }
                                                img={card.node.data.img.url}
                                                date={Moment(
                                                    card.node.data.datetime
                                                ).format("DD MMMM, YYYY")}
                                                time={Moment(
                                                    card.node.data.datetime
                                                ).format("h A")}
                                                uid={card.node.uid}
                                                category={
                                                    card.node.data.categories
                                                        ? card.node.data
                                                              .categories
                                                              .length > 0
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                        : ""
                                                }
                                                content={
                                                    card.node.data.content.text
                                                }
                                                feed
                                                key={i}
                                            />
                                        );
                                    }
                                }
                            })}
                            <h1
                                className={css`
                                    ${tw`w-full text-black font-thin opacity-40 m-0 pl-8 mt-4`}
                                `}
                            >
                                Gallery
                            </h1>
                            {this.cards.gallery.map((card, i) => {
                                // Skip duplicates
                                if (
                                    // card.node.uid !==
                                    //     (one.hasOwnProperty("node") &&
                                    //         one.node.uid) &&
                                    // card.node.uid !==
                                    //     (two.hasOwnProperty("node") &&
                                    //         two.node.uid) &&
                                    // card.node.uid !==
                                    //     (three.hasOwnProperty("node") &&
                                    //         three.node.uid) &&
                                    // card.node.uid !==
                                    //     (four.hasOwnProperty("node") &&
                                    //         four.node.uid) &&
                                    // card.node.uid !==
                                    //     (five.hasOwnProperty("node") &&
                                    //         five.node.uid)
                                    true
                                ) {
                                    // Check when to insert an ad card
                                    console.log(i, (i + 1) % 7);
                                    if ((i + 1) % 10 === 0) {
                                        return (
                                            <React.Fragment key={i}>
                                                <HorizontalCard
                                                    title={
                                                        card.node.data.title
                                                            .text
                                                    }
                                                    thirdPartyUrl={
                                                        card.node.data
                                                            .third_party_url
                                                    }
                                                    img={card.node.data.img.url}
                                                    date={Moment(
                                                        card.node.data.datetime
                                                    ).format("DD MMMM, YYYY")}
                                                    time={Moment(
                                                        card.node.data.datetime
                                                    ).format("h A")}
                                                    uid={card.node.uid}
                                                    category={
                                                        card.node.data
                                                            .categories
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                    }
                                                    content={
                                                        card.node.data.content
                                                            .text
                                                    }
                                                    feed
                                                />

                                                {/* Ad Card takes the space of 2 news cards */}
                                                <div
                                                    style={{ width: "100%" }}
                                                    className="ad-container"
                                                >
                                                    <Adsense.Google
                                                        client="ca-pub-5630104718741028"
                                                        slot="9699731559"
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            display: "block",
                                                            height: 90,
                                                            width: 728,
                                                        }}
                                                        format="auto"
                                                        responsive="true"
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <HorizontalCard
                                                title={
                                                    card.node.data.title.text
                                                }
                                                thirdPartyUrl={
                                                    card.node.data
                                                        .third_party_url
                                                }
                                                img={card.node.data.img.url}
                                                date={Moment(
                                                    card.node.data.datetime
                                                ).format("DD MMMM, YYYY")}
                                                time={Moment(
                                                    card.node.data.datetime
                                                ).format("h A")}
                                                uid={card.node.uid}
                                                category={
                                                    card.node.data.categories
                                                        ? card.node.data
                                                              .categories
                                                              .length > 0
                                                            ? card.node.data
                                                                  .categories[0]
                                                                  .category
                                                                  .document[0]
                                                                  .data.name
                                                            : ""
                                                        : ""
                                                }
                                                content={
                                                    card.node.data.content.text
                                                }
                                                feed
                                                key={i}
                                            />
                                        );
                                    }
                                }
                            })}
                        </Row>
                    </SectionTwo>
                    <Adsense.Google
                        client="ca-pub-5630104718741028"
                        slot="9699731559"
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            height: 90,
                            width: 728,
                        }}
                        format="auto"
                        responsive="true"
                    />
                </Layout>
            </>
        );
    }
}

export default HomePage;

export const pageQuery = graphql`
    query {
        all: allPrismicArticle(
            filter: { tags: { ne: "ahl" }, data: { active: { eq: "Y" } } }
            sort: { fields: [data___datetime], order: DESC }
            limit: 75
        ) {
            edges {
                node {
                    tags
                    data {
                        third_party_url
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        featured: allPrismicArticle(
            filter: { tags: { eq: "featured" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        politics: allPrismicArticle(
            filter: { tags: { eq: "politics" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        entertainment: allPrismicArticle(
            filter: {
                tags: { eq: "entertainment" }
                data: { active: { eq: "Y" } }
            }
            limit: 10
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        tech: allPrismicArticle(
            filter: { tags: { eq: "tech" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        fashion: allPrismicArticle(
            filter: { tags: { eq: "fashion" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        business: allPrismicArticle(
            filter: { tags: { eq: "business" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        health: allPrismicArticle(
            filter: { tags: { eq: "health" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        nri: allPrismicArticle(
            filter: { tags: { eq: "nri" }, data: { active: { eq: "Y" } } }
            limit: 1
        ) {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }

        gallery: allPrismicGalleryarticle(
            filter: { tags: { eq: "gallery" } }
            sort: { fields: [last_publication_date], order: DESC }
            limit: 20
        ) {
            edges {
                node {
                    tags
                    last_publication_date
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                    }
                    uid
                }
            }
        }

        review: allPrismicReviewarticle(
            filter: { data: { active: { eq: "Y" } } }
            sort: { fields: [data___datetime], order: DESC }
            limit: 20
        ) {
            edges {
                node {
                    tags
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        img {
                            url
                        }
                        datetime
                        embed {
                            embed_url
                        }
                    }
                    uid
                }
            }
        }
    }
`;

// p3p: CP="This is not a P3P policy! See http://support.google.com/accounts/answer/151657 for more info."
