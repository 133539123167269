import React from "react";
import tw from "tailwind.macro";
import styled from "styled-components";
import { css } from "emotion";
import Row from "./Row";
import { Link } from "gatsby";

import { Transition, animated, interpolate } from "react-spring/renderprops";

const Wrapper = styled.div`
    ${tw`w-full 
        flex 
        flex-wrap 
        items-end 
        bg-white 
        rounded-lg 
        relative 
        z-0 
        overflow-hidden 
        text-black 
        my-4
        p-4 
        m-2
        mx-auto 
        lg:mx-0
        cursor-pointer
        hover:shadow-lg`};
    transition: all 0.25s ease-in-out;
    ${(props) => props.hero && tw`p-4 lg:p-8`};
    ${(props) => props.hero && `height: 15rem; width: 100%; max-width: 20rem;`};

    ${(props) => props.wide && tw`w-full lg:w-49p p-4 lg:p-8`};

    ${(props) => props.wide && `height: 16rem;`};

    ${(props) => !props.wide && !props.hero && `height: 12rem;`};

    ${(props) => props.side && `height: 15rem;`};

    ${(props) =>
        props.feed &&
        !props.related &&
        `
        @media (min-width: 992px){
            width: 20rem;
            &:nth-of-type(even){
                // margin-top: 4rem;
            }
        }
        width: 40rem;
        height: 22rem;
        margin: 1rem !important;
        // margin-left: 1rem !important;
        // margin-right: 1rem !important;
    `}

    ${(props) =>
        props.related &&
        `
        min-width: 20rem;
        width: 20rem;
        margin-right: 2rem !important;
    `}
`;
const StyledImage = styled.div`
    ${tw`absolute h-full w-full pin-t pin-l`};
    z-index: -2;
    mask-image: linear-gradient(black 30%, transparent 80%);
    -webkit-mask-image: linear-gradient(black 30%, transparent 80%);
    ${(props) => `background-image: url(${props.image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    ${(props) =>
        props.feed &&
        `
        // height: 60%;
    `}
`;
const ImageLayer = styled.div`
    ${tw`absolute h-full w-full pin-t pin-l`};
    z-index: -1;
    // background: rgba(0,0,0,0.5);
    // background-blend-mode: multiply;
    // -webkit-background-blend-mode: multiply;
`;
const Title = styled.h2`
    ${(props) => (props.hero ? tw`text-xl` : tw`text-base w-full`)};

    /* Ensures title does not exceed 2 lines */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 1.4; /* Adjust for better readability */
`;
const CategoryLabel = styled.p`
    ${tw`absolute z-100 text-xs font-semibold px-4 py-1 rounded-full  uppercase`};
    top: 8%;
    right: 5%;
    color: #1e1e1e;
    opacity: 0.4;
    letter-spacing: 1.66px;
    text-align: center;
    background: rgba(228, 233, 249, 1);
`;

const AnimatedWrapper = animated(Wrapper);
const Card = (props) => (
    <Wrapper {...props}>
        <CategoryLabel>{props.category}</CategoryLabel>
        <Row justifyBetween>
            <Title style={{ marginBottom: -10 }} hero={props.hero}>
                {props.title}
            </Title>
            {/* <div className={css`${tw`flex items-center w-full h-6`}`}>
                <div className={css`${tw`inline-flex items-center uppercase tracking-wide`}`}>
                    <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/calendar.svg')}/>
                    <p className={css`${tw`text-xxs opacity-70 text-grey-dark`}`}>{props.date}</p>
                </div>
                <div className={css`${tw`inline-flex items-center ml-4`}`}>
                    <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/clock.svg')}/>
                    <p className={css`${tw`text-xxs opacity-70 text-grey-dark uppercase tracking-wide`}`}>{props.time}</p>
                </div>
            </div> */}
        </Row>
        <ImageLayer />
        <StyledImage image={props.img} />
        <Link
            className={css`
                ${tw`absolute z-50 h-full w-full pin-t pin-l no-underline`}
            `}
            to={props.uid + "/"}
        ></Link>
    </Wrapper>
);

export default Card;
