import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { css } from 'emotion';
import Row from './Row'
import { Link } from 'gatsby';

import { Transition, animated, interpolate } from 'react-spring/renderprops';

const Wrapper = styled.div`
    ${tw`
        w-full 
        flex 
        flex-wrap 
        items-end 
        bg-white 
        rounded-lg 
        relative 
        z-0 
        overflow-hidden 
        text-black 
        my-4
        mx-auto 
        lg:mx-0
        cursor-pointer
        hover:shadow-lg
    `};
    transition: all 0.25s ease-in-out;  
    
    /* ✅ 2 per row on mobile */
    width: 48%; 
    
    @media (min-width: 768px) { /* 📱 Tablet */
        width: 32%; /* 3 per row */
    }

    @media (min-width: 1024px) { /* 🖥 Desktop */
        width: 19%; /* 5 per row */
    }
`;

const StyledImage = styled.div`
    ${tw`w-full`};
    height: 120px; /* Uniform image height */
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const CategoryLabel = styled.p`
    ${tw`absolute text-xs font-semibold px-3 py-1 rounded-full uppercase`};
    top: 5%;
    left: 5%;
    color: #1E1E1E;
    opacity: 0.6;
    background: rgba(228, 233, 249, 0.8);
`;

const ContentSection = styled.div`
    ${tw`p-3 flex flex-col justify-between`};  
`;

const Title = styled.h3`
    ${tw`text-base`};
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 🔥 Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3rem; /* Ensures proper spacing */
    line-height: 1.5;
    word-break: break-word;
    font-weight: 500;
    height: 50px;
`;

const Content = styled.p`
    ${tw`text-sm opacity-80`};
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const HorizontalCard = (props) => {
    return (
        <Wrapper>
            {/* <CategoryLabel>{props.category}</CategoryLabel> */}
            <StyledImage image={props.img} />
            <ContentSection>
                <Title>{props.title}</Title>
                {/* <Content>{props.content}</Content> */}
                <div className={css`${tw`flex items-center w-full mt-2`}`}>
                    <div className={css`${tw`inline-flex items-center text-xs uppercase tracking-wide`}`}>
                        <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/calendar.svg')} />
                        <p className={css`${tw`opacity-70 text-grey-dark`}`}>{props.date}</p>
                    </div>
                    {/* <div className={css`${tw`inline-flex items-center ml-4`}`}>
                        <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/clock.svg')} />
                        <p className={css`${tw`opacity-70 text-grey-dark uppercase tracking-wide`}`}>{props.time}</p>
                    </div> */}
                </div>
            </ContentSection>
            <Link className={css`${tw`absolute w-full h-full`}`} to={props.uid + "/"} />
        </Wrapper>
    );
};

export default HorizontalCard;